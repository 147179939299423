<!-- =========================================================================================
	File Name: TableMiscellaneous.vue
	Description: Combine filter,sorter, pagination etc.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <receipt forwho="s" :orderid="orderHeaderId" :watermark="watermark"></receipt>
    <div class="pagebreak"></div>
    <receipt forwho="c" :orderid="orderHeaderId" :watermark="watermark"></receipt>
  </div>
</template>


<script>
import Receipt from "./Receipt.vue";
export default {
  data() {
    return {
      orderHeaderId: "",
      watermark: true
    };
  },
  created() {
    this.orderHeaderId = this.$route.params.orderid;
    var wm = this.$route.params.watermark;

    if (wm === "f") {
      this.watermark = false;
    } else {
      this.watermark = true;
    }
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 4500);
  },
  components: {
    Receipt
  }
};
</script>
<style>
@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>
